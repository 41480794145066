import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { useState, useEffect } from 'react';


const URL = 'https://accounting.domopolis.com.ua/dpapi/hs/dashboard/balance';
const URL0 = 'https://accounting.domopolis.com.ua/api/hs/api/balance';

function App() {
  
  const [temp, setTemp] = useState(0)
  const [tempua, setTempua] = useState(0)
  const [cur, setCur] = useState(0)
  const [datecur, setDatecur] = useState(0)
  const [instock, setInstock] = useState(0)
  const [instockua, setInstockua] = useState(0)
  const [indelivery, setIndelivery] = useState(0)
  const [indeliveryua, setIndeliveryua] = useState(0)
  const [customedebt, setCustomedebt] = useState(0)
  const [customedebtua, setCustomedebtua] = useState(0)
  const [debttobuyers, setDebttobuyers] = useState(0)
  const [debttobuyersua, setDebttobuyersua] = useState(0)
  const [balancedebt, setBalancedebt] = useState(0)
  const [balancedebtua, setBalancedebtua] = useState(0)

  const [orderssupplier, setOrderssupplier] = useState(0)
  const [ordersbuyerua, setordersbuyerua] = useState(0)
  const [ordersstock, setOrdersstock] = useState(0)
  const [ordersstockua, setOrdersstockua] = useState(0)
  const [demandua, setdemandua] = useState(0)
   
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(URL)
      result.json().then(json => {
        setTemp(json.data.accountbalance)
        setCur(json.data.exchangerate)
        setDatecur(json.data.exchangeratedate)
        setTempua(json.data.accountbalanceUAH)
        setInstock(json.data.instock)
        setInstockua(json.data.instockUAH)
        setIndelivery(json.data.indelivery)
        setIndeliveryua(json.data.indeliveryUAH)
        setCustomedebt(json.data.customedebt)
        setCustomedebtua(json.data.customedebtuah)
        setDebttobuyers(json.data.debttobuyers)
        setDebttobuyersua(json.data.debttobuyersuah)
        setBalancedebt(json.data.balancedebt)
        setBalancedebtua(json.data.balancedebtuah)
      })
      const result0 = await fetch(URL0)
      result0.json().then(json => {
        setOrderssupplier(json.data.orderssupplier)
        setordersbuyerua(json.data.ordersbuyeruah)
        setOrdersstock(json.data.ordersstock)
        setOrdersstockua(json.data.ordersstockuah)
        setdemandua(json.data.demandUAH)
      })  

    }
    fetchData();
  },[]);

  return (
    <>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Domopolis - Dashboard
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align="right">
            {datecur} - Курс EUR: {cur}
          </Typography>
          {/*<Button color="inherit">Login</Button>*/}
        </Toolbar>
      </AppBar>
    </Box>
  
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      '& > :not(style)': {
        m: 1,
        width: 128,
        height: 128,
      },
    }}
    >
      <Card sx={{ minWidth: 275 }} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Залишок коштів на рахунках
        </Typography>
        <Typography variant="h5" component="div">
          {temp} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {tempua} UAH
        </Typography>
        <Typography variant="body2">
          фактично в UAH, EUR по курсу
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

    
    
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Заборгованість покупців
        </Typography>
        <Typography variant="h5" component="div">
          {customedebt} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {customedebtua} UAH
        </Typography>
        <Typography variant="body2">
          фактично в UAH, EUR по курсу
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    <Card sx={{ minWidth: 275 }} style={{background:"#FFEFD5"}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Передплати покупців
        </Typography>
        <Typography variant="h5" component="div">
          {debttobuyers} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {debttobuyersua} UAH
        </Typography>
        <Typography variant="body2">
          фактично в UAH, EUR по курсу
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Баланс розрахунків з покупцями
        </Typography>
        <Typography variant="h5" component="div">
          {balancedebt} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {balancedebtua} UAH
        </Typography>
        <Typography variant="body2">
          фактично в UAH, EUR по курсу
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
   
    
    </Box>
    <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      '& > :not(style)': {
        m: 1,
        width: 128,
        height: 128,
      },
    }}
    >
    <Card sx={{ minWidth: 275 }} style={{background:"#FFEFD5"}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Необхідо закупити
        </Typography>
        <Typography variant="h5" component="div">
         {/* {ordersstock} EUR */} {(Math.round(demandua / cur / 2)).toLocaleString()} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {demandua.toLocaleString()} UAH
        </Typography>
        <Typography variant="body2"> 
        EUR - (прод/2/курс), UAH - прод.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Замовлено у постачальників
        </Typography>
        <Typography variant="h5" component="div">
          {orderssupplier} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {ordersbuyerua} UAH
        </Typography>
        <Typography variant="body2">
        EUR - закуп., UAH - прод.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

    
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Прийнято на складі DE
        </Typography>
        <Typography variant="h5" component="div">
          {ordersstock} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {ordersstockua} UAH
        </Typography>
        <Typography variant="body2">
        EUR - закуп., UAH - прод.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>

    <Card sx={{ minWidth: 275 }} >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Товар в дорозі з DE
        </Typography>
        <Typography variant="h5" component="div">
          {indelivery} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {indeliveryua} UAH
        </Typography>
        <Typography variant="body2">
         EUR - закуп., UAH - прод.
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    <Card sx={{ minWidth: 275 }} style={{background:"#FFEFD5"}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Залишки товарів на складі UA
        </Typography>
        <Typography variant="h5" component="div">
          {instock} EUR
          {/*be{bull}nev{bull}o{bull}lent */}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {instockua} UAH
        </Typography>
        <Typography variant="body2">
          Собівартість в EUR, UAH по курсу
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </Box>
  </>
  );
}

export default App;
